<script>
import { analytics, db, functions,firebaseApp } from '../firebase';
import { mapState } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';

import formatMoney from '@/lib/formatMoney';
import formatDate from '@/mixins/formatDate';
import trialHelpers from '@/mixins/trialHelpers';
import featureEnabled from '@/mixins/featureFlag';
import subsciptionHelpers from '@/mixins/subscriptionHelpers';

export default {
	name: 'Subscription',
	mixins: [formatDate, trialHelpers, featureEnabled, subsciptionHelpers],
	computed: {
		...mapState(['plans', 'user', 'subscriptions']),
		flpPlans() {
			return this.plans.filter(({ role }) => role !== 'assistant_plan');
		},
		assistantPlan() {
			return this.plans.find(({ role }) => role === 'assistant_plan');
		},
	},
	data() {
		return {
			loading: false,
		};
	},
	watch: {
		plans() {
			if (this.user && !this.subscriptions)
				this.$store.dispatch('getSubscriptions', this.user);
		},
	},
	mounted() {
		this.getPlans();
		this.$store.dispatch('fetchCurrentCustomerProfile')
		analytics.logEvent('screen_view', {
			firebase_screen: 'subscripitons',
			firebase_screen_class: 'Subscription',
		});
	},
	methods: {
		getPlans() {
			this.$store.dispatch('getPlans');
		},
		async startSubscription(plan) {
			analytics.logEvent('subscriptions_plan_clicked');
			this.loading = true;
			const price = plan.prices[0];

			const docRef = await db
				.collection('customers')
				.doc(this.user.uid)
				.collection('checkout_sessions')
				.add({
					allow_promotion_codes: true,
					// automatic_tax: { enabled: true },
					price: price.id,
					// quantity, todo
					success_url: window.location.origin,
					cancel_url: window.location.origin,
					tax_rates:
						window.location.hostname.split('.')[0] === 'agreements'
							? [
									'txr_1KjtoMGphw9MlUMTQGwfXGaQ',
									'txr_1KnUEJGphw9MlUMTerBLpNcz',
							  ]
							: [
									'txr_1KhLWaGphw9MlUMTuqJYPB5L',
									'txr_1KnU1KGphw9MlUMTgDKkZxY9',
							  ],
					// mode: 'subscription'	// default
					// customer_update: { // default with automatic_tax
					// 	address: 'auto',
					// },
					// billing_address_collection: 'required', //default
					// subscription_data: { default_tax_rates: [] },
				});
			// Wait for the CheckoutSession to get attached by the extension
			docRef.onSnapshot(async (snap) => {
				const { error, sessionId } = snap.data();
				if (error) {
					// Show an error to your customer and
					// inspect your Cloud Function logs in the Firebase console.
					console.error(`An error occured: ${error.message}`);
				}
				if (sessionId) {
					// We have a session, let's redirect to Checkout
					// Init Stripe
					const stripe = await loadStripe(
						process.env.VUE_APP_STRIPE_KEY
					);
					stripe.redirectToCheckout({ sessionId });
				}
				this.loading = false;
			});
		},
		async customerPortal() {
			analytics.logEvent('subscriptions_customer_portal_clicked');
			const functionRef = functions.httpsCallable(
				'ext-firestore-stripe-subscriptions-createPortalLink'
			);
			const { data } = await functionRef({
				returnUrl: window.location.origin,
			});
			window.location.assign(data.url);
		},
		getPrice(plan) {
			const price = plan.prices.find(
				({ interval }) => interval == 'month'
			);
			return price ? formatMoney(price.unit_amount / 100) : '';
		},
		subscribeClick(plan){
			if(this.activeSubscription && this.activeSubscription.role !== plan.role){
				this.updateSubscriptionByCustomerPortalLink()
			} else {
				this.startSubscription(plan);
			}
		},
		async updateSubscriptionByCustomerPortalLink(){
			try {
				this.loading = true;
                const createStripeCustomerPortalLinkFc = firebaseApp.functions('northamerica-northeast1').httpsCallable('createStripeCustomerPortalDashboardLink');
				const reqBody = {
					customerId: this.user.uid,
					success_url: window.location.origin,
					cancel_url: window.location.origin
				}
				const linkResponse = await createStripeCustomerPortalLinkFc(reqBody);
				if(linkResponse?.data?.customerPortalLink)
					window.location.assign(linkResponse.data.customerPortalLink);
				this.loading = false;
			} catch (error) {
				console.error(error);
				this.loading = false;
			}
		}
		
	},
};
</script>

<template>
	<div class="px-5 container-fluid container-outer">
		<b-row class="py-5 text-center">
			<b-col>
				<h1 class="mb-5">
					Automate your process. Streamline your practice.
				</h1>
				<p>
					Choose the plan that supports all your clients and watch
					your practice thrive!
				</p>
			</b-col>
		</b-row>
		<b-row class="justify-content-md-center">
			<b-col sm="auto">
				<b-card-group deck>
					<b-card
						v-for="plan in flpPlans"
						:key="plan.name"
						:title="plan.name.toUpperCase()"
						:class="{ 'active-plan': activeSubscription &&
								activeSubscription.role === plan.role }"
						class="plan text-center"
						style="max-width: 420px"
					>
						<b-card-text>
							<h1 class="price">${{ getPrice(plan) }}</h1>
							<div><small>per month (+ tax)</small></div>
							<div class="mt-3 font-weight-bold">
								Early Bird Price
							</div>
							<!-- <div class="mt-4">
								Valid through to Dec 31, 2022
							</div> -->
							<div class="features mt-5">
								<ul>
									<li class="mb-1">
										Automated Agreement Builder & Preview
									</li>
									<li class="mb-1">
										Property Distribution Calculator
									</li>
									<li class="mb-1">
										Parenting Planner & Scheduler
									</li>
									<li class="mb-1">
										Integrated Editor with best of class
										formatting
									</li>
									<li class="mb-1">
										Option to Download as PDF or Print
									</li>
									<li class="mb-1">
										Advanced Security & Data Encryption
									</li>
									<li class="mb-1">
										Cloud storage on Canadian servers
									</li>
									<li class="mb-1 font-weight-bold">
										{{
											plan.role === 'unlimited'
												? 'UNLIMITED Agreement Files per Month'
												: '2 Agreement Files per Month'
										}}
									</li>
								</ul>
							</div>
						</b-card-text>

						<b-button
							variant="primary"
							:disabled="loading || (activeSubscription &&
								activeSubscription.role === plan.role)"
							@click="subscribeClick(plan)"
						>
							<b-spinner small type="grow" v-if="loading && !(activeSubscription &&
								activeSubscription.role === plan.role)"></b-spinner>
							{{
								activeSubscription &&
								activeSubscription.role === plan.role
									? 'Current Plan'
									: 'Select Plan'
							}}</b-button
						>
					</b-card>
				</b-card-group>
			</b-col>
		</b-row>
		<!-- ASSISTANT CODE DELETED FOR THE TIME BEING. TO BE ADDED LATER -->
		<!-- <b-row
			class="mt-5"
			v-if="
				assistantPlan && featureEnabled('feature_assistant_management')
			"
		>
			<b-col>
				<b-card class="plan">
					<b-row no-gutters>
						<b-col sm="3">
							<b-card-body
								:title="assistantPlan.name"
								class="text-center"
							>
								<h1 class="price">
									${{ getPrice(assistantPlan) }}
								</h1>
								<b-button
									variant="primary"
									@click="startSubscription(assistantPlan)"
									:disabled="hasAssistantSubscription"
								>
									Add Assistant
								</b-button>
							</b-card-body>
						</b-col>
						<b-col sm="9">
							<b-card-body>
								<b-card-text class="features">
									Full access to collaborate on the files your
									lawyer has on the go.
								</b-card-text>
							</b-card-body>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row> -->
		<!-- <b-row class="mt-5 text-center">
			<b-col>
				<p>Questions? Divii experts are here to help.</p>
			</b-col>
		</b-row> -->
	</div>
</template>
<style>
.plan {
	color: #3F6772;
}
.plan.active-plan{
	border:4px solid #3F6772;
}

.price {
	color: #3F6772;
}

.features {
	min-width: 250px;
	color: #3F6772;
}

.features ul {
	list-style: none;
	margin-left: -40px;
}

.features ul li:before {
	content: '✓  ';
}

.container-outer{
	background: #f7f5f5;
	padding-bottom: 2rem;
}
</style>
