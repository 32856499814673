import { mapState } from 'vuex';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';

import formatDate from '@/mixins/formatDate';

export default {
	computed: {
		...mapState(['userProfile', 'agreements']),

		trialActive() {
			const { trialEnd, subscriptionStatus } = this.userProfile;
			return subscriptionStatus
				? false
				: trialEnd && isAfter(parseISO(trialEnd), new Date());
		},
		trialExpired() {
			const { trialEnd, subscriptionStatus } = this.userProfile;
			return subscriptionStatus
				? false
				: trialEnd && !isAfter(parseISO(trialEnd), new Date());
		},
		trialAgreementId() {
			return this.agreements.length ? this.agreements[0].id : null;
		},
	},
	mixins: [formatDate],
};
